<div class="pageBlocksCover overlay" style="background-image: url('./assets/img/bgimg.jpg');" data-aos="fade" data-stellar-background-ratio="0.5">
    <div class="container">
        <div class="row align-items-center justify-content-center text-center">
            <div class="col-md-12" data-aos="fade-up" data-aos-delay="400">
                <div class="row justify-content-center mb-4" style="padding-top: -250px;">
                    <div class="col-md-8 text-center">
                        <h1>Vytvárame aplikácie pre <br /><span class="tWords"></span></h1>
                        <p class="lead mb-5">SAJA Soft, s.r.o.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>




