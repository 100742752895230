<div class="pageBlocksCover overlay" id="naviAkoNaTo" style="background-image: url('./assets/img/bgimg2.jpg');" data-aos="fade" data-stellar-background-ratio="0.5">
    <div class="container">
        <div class="row align-items-center justify-content-center text-center mb-5">
            <div class="col-md-12 text-center">
                <div class="textBox">
                    <div class="textBoxText">Ako na to</div>
                </div>
                <p class="subTextBoxText">... tri jednoduché kroky</p>
            </div>

            <div class="col-md-6 col-lg-4 mb-4 mb-lg-4" data-aos="fade-up">
                <div class="unit d-flex">
                    <div class="pr-3 pb-1 numberBox">
                        <div class="number">01.</div>
                        <h2>Konzultácia</h2>
                        <p>V rámci bezplatnej konzultácie si s Vami preberieme Vaše požiadavky.</p>
                    </div>
                </div>
            </div>
            <div class="col-md-6 col-lg-4 mb-4 mb-lg-4" data-aos="fade-up" data-aos-delay="400">
                <div class="unit d-flex">
                    <div class="pr-3 pb-1 numberBox">
                        <div class="number">02.</div>
                        <h2>Nacenenie</h2>
                        <p>Vypočítanie celkovej ceny za aplikáciu. Po dohodnutí ceny sa začína samotný vývoj aplikácie.</p>
                    </div>
                </div>
            </div>
            <div class="col-md-6 col-lg-4 mb-4 mb-lg-4" data-aos="fade-up" data-aos-delay="800">
                <div class="unit d-flex">
                    <div class="pr-3 pb-1 numberBox">
                        <div class="number">03.</div>
                        <h2>Realizácia</h2>
                        <p>Čas potrebný na realizáciu aplikácie záleží na jej náročnosti a rozsahu. Počas celej doby, na základe vzájomnej komunikácii, realizujeme Vaše pripomienky.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>