import { Component, OnInit } from '@angular/core';

declare var Typed: any;

@Component({
  selector: 'cover-component',
  templateUrl: './cover.component.html'
})

export class CoverComponent implements OnInit {

    ngOnInit() {

      var typed = new Typed('.tWords', {
          strings: ["Mobil Android/iOS", "Web", "Windows"],
          typeSpeed: 30,
          backSpeed: 20,
          backDelay: 4000,
          startDelay: 1000,
          loop: true,
          showCursor: true
      });

    }
}