<section class="pageSection bg-light border-top" id="naviKontakt">
    <div class="container">
        <div class="row mb-5 pt-5">
        <div class="col-12 text-center">
            <h2 class="text-black h1 pageSectionHead">Kontakt</h2>
        </div>
        </div>
        <div class="row">
            <div *ngIf="!isSendingMail" class="col-md-7 mb-1">
                <form  [formGroup]="contact" (ngSubmit)="sendMail()" class="p-5 bg-white">
                <h2 class="h4 text-black mb-5">Kontaktný formulár</h2>

                <div class="row form-group">
                    <div class="col-md-6 mb-3 mb-md-0">
                        <label class="text-black">Meno</label>
                        <input type="text" formControlName="name" class="form-control">
                    </div>
                    <div class="col-md-6">
                        <label class="text-black">Priezvisko</label>
                        <input type="text"  formControlName="surName" class="form-control">
                    </div>
                </div>

                <div class="row form-group">

                    <div class="col-md-12">
                        <label class="text-black">Email</label>
                        <input type="email" formControlName="email" class="form-control">
                    </div>
                </div>

                <div class="row form-group">

                    <div class="col-md-12">
                        <label class="text-black">Nadpis</label>
                        <input type="text" formControlName="subject" class="form-control">
                    </div>
                </div>

                <div class="row form-group">
                    <div class="col-md-12">
                        <label class="text-black">Správa</label>
                        <textarea formControlName="message" cols="30" rows="6" class="form-control" placeholder="Sem napíšte svoju správu..."></textarea>
                    </div>
                </div>

                <div class="row form-group">
                    <div class="col-md-12">
                        <re-captcha [siteKey]="publicKey" required formControlName="captcha"></re-captcha>
                    </div>
                </div>

                <div class="row form-group">
                    <div class="col-md-12">
                        <button id="btnSubmit" [disabled]="contact.invalid" type="submit" class="btn btn-primary btn-md text-white">Odoslať správu</button>
                    </div>
                </div>


                </form>
            </div>
            <div *ngIf="isSendingMail" class="col-md-7 mb-1">
                
                <form  [formGroup]="contact" class="p-5 bg-white">
                <h2 class="h4 text-black mb-5">Kontaktný formulár</h2>
                <div class="row form-group">
                    <div class="col-md-6 mb-3 mb-md-0">
                        <label class="text-black" >Meno</label>
                        <h4>{{contact.controls.name.value}}</h4>
                    </div>
                    <div class="col-md-6">
                        <label class="text-black">Priezvisko</label>
                        <h4>{{contact.controls.surName.value}}</h4>
                    </div>
                </div>
                <div class="row form-group">
                    <div class="col-md-12">
                        <label class="text-black">Email</label>
                        <h4>{{contact.controls.email.value}}</h4>
                    </div>
                </div>
                <div class="row form-group">
                    <div class="col-md-12">
                        <label class="text-black">Nadpis</label>
                        <h4>{{contact.controls.subject.value}}</h4>
                    </div>
                </div>
                <div class="row form-group">
                    <div class="col-md-12">
                        <label class="text-black">Správa</label>
                        <h4>{{contact.controls.message.value}}</h4>
                    </div>
                </div>
                <div class="row form-group">
                    <div class="col-md-12">
                        <label class="text-green">Správa úspešne odoslaná.</label>
                    </div>
                </div>
                </form>
            </div>
            <div class="col-md-5">
            <div class="p-4 mb-3 bg-white">
                <h4 class="mb-0 font-weight-bold">SAJA Soft, s.r.o.</h4><br/>
                <p class="mb-0 font-weight-bold">Adresa</p>
                <p class="mb-0">Československej armády 156/83A</p>
                <p class="mb-0">96233 Budča</p>
                <p class="mb-4">Slovensko</p>

                <p class="mb-0 font-weight-bold">Mobil</p>
                <p class="mb-4"><a href="tel:+421907441541">+421 907 441 541</a></p>

                <p class="mb-0 font-weight-bold">Email adresa</p>
                <p class="mb-0"><a href="mailto:info@sajasoft.sk">info@sajasoft.sk</a></p>
            </div>
            <div class="p-4 mb-3 bg-white">
                <h4>Fakturačné údaje</h4>
                <p class="mb-0 font-weight-bold">Číslo účtu</p>
                <p class="mb-4">SK63 1100 0000 0029 4111 0083</p>

                <p class="mb-0 font-weight-bold">IČO</p>
                <p class="mb-4">53975502</p>

                <p class="mb-0 font-weight-bold">DIČ</p>
                <p class="mb-4">2121538936</p>

                <p class="mb-0 font-weight-bold">IČ DPH</p>
                <p class="mb-0">SK2121538936</p>
            </div>
            </div>
        </div>
    </div>
</section>