import { Injectable } from '@angular/core';
import { first } from 'rxjs/operators';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { SettingsService } from '../../app/services/settings.services';

@Injectable()
export class SendMailService {

constructor(private http: HttpClient, private settings: SettingsService) {   
}

sendMail(subject: string, message: string) {
    const httpOptions = {
          headers: new HttpHeaders({
              'Content-Type':  'application/x-www-form-urlencoded'
        })
    };
    const body = new HttpParams().set('subject', subject).set('message', message);
    this.http.post<any>(this.settings.emailServiceUrl, body, httpOptions).pipe(first()).subscribe(() => {},
          error => {
              console.log(error);
        });
    }
}