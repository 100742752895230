import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { SendMailService } from '../../services/sendmail.services';
import { SettingsService } from '../../services/settings.services';

@Component({
  selector: 'contact-component',
  templateUrl: './contact.component.html'
})

export class ContactComponent {
    publicKey: string;
    isSendingMail: boolean;
    contact = new FormGroup({
      name: new FormControl(null, Validators.required),
      surName: new FormControl(null, Validators.required),
      email: new FormControl(null, Validators.required),
      subject: new FormControl(null, Validators.required),
      message: new FormControl(null, Validators.required),
      captcha: new FormControl(),
});

  constructor (private mailService: SendMailService, private settings: SettingsService) {
    this.isSendingMail = false;
    this.publicKey = this.settings.recaptchaSiteKey;
  }

  sendMail() {
    const body = this.contact.controls.name.value + " " + this.contact.controls.surName.value + "\r" + this.contact.controls.email.value + "\r\rSpráva:\r" + this.contact.controls.message.value;
    this.mailService.sendMail(this.contact.controls.subject.value, body);
    this.isSendingMail = true;
  }
}