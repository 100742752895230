<div class="border-bottom top-bar py-2 bg-dark" id="naviHome">
    <div class="container">
        <div class="row">
            <div class="col-md-6">
                <p class="mb-0">
                    <span><a href="tel:+421907441541">+421 907 441 541</a></span><strong class="text-white"> | </strong>
                    <span><a href="mailto:info@sajasoft.sk">info@sajasoft.sk</a></span>
                </p>
            </div>
        </div>
    </div>
</div>

