import { Injectable } from '@angular/core';


@Injectable()
export class SettingsService {
    public emailServiceUrl: string = 'https://www.sajasoft.sk/Service/service.php';
    public recaptchaSiteKey: string = '6Leg1LEZAAAAANwalHTQAVM1fganEEfJI8AjjTMU';
    //sajasoft.sk    6Leg1LEZAAAAANwalHTQAVM1fganEEfJI8AjjTMU 
    //localhost      6LdTf_MZAAAAAGV7wk4TaVmL5V1pXZ2SAXjcQX3W
}
