import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { NgcCookieConsentModule, NgcCookieConsentConfig } from 'ngx-cookieconsent';
import { HttpClientModule } from '@angular/common/http';

import { AppComponent } from './app.component';
import { ContactComponent} from './screens/contact/contact.component';
import { HowOnItComponent } from './screens/how-on-it/how-on-it.component';
import { CoverComponent } from './screens/cover/cover.component';
import { NavigationComponent } from './screens/navigation/navigation.component';
import { TechnologyRealizationComponent } from './screens/technology-realization/technology-realization.component';
import { RecaptchaFormsModule, RecaptchaModule } from 'ng-recaptcha';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { SendMailService } from '../app/services/sendmail.services';
import { SettingsService } from '../app/services/settings.services';

//Nastavenie na stranke
//https://tinesoft.github.io/ngx-cookieconsent/home
const cookieConfig:NgcCookieConsentConfig = {
  cookie: {
    domain: "sajasoft.sk"
  },
  position: "bottom",
  theme: "classic",
  palette: {
    popup: {
      background: "rgba(0,0,0,0.6)",
      text: "#48b7ff",
      link: "#ffffff"
    },
      button: {
      background: "#48b7ff",
      text: "#ffffff",
      border: "transparent"
    }
  },
  type: "info",
  layout: 'my-custom-layout',
  layouts: {
    "my-custom-layout": '{{element}}{{compliance}}'
  },
  elements:{
    element: `
    <span id="cookieconsent:desc" class="cc-message">
      <h4>{{header}}</h4><h5 style="color: white;">{{message}}
      <a aria-label="learn more about cookies" tabindex="0" class="cc-link" href="{{href}}" target="_blank">{{link}}</a></h5> 
    </span>
    `,
  },
  content: {
    message: "Používaním našej webovej lokality vyjadrujete svoj súhlas s používaním a ukladaním súborov cookie vo vašom koncovom zariadení.",
    dismiss: "Súhlasím",
    deny: "Nesúhlasím",
    link: "Viac informácií",
    href: "https://en.wikipedia.org/wiki/HTTP_cookie",
    policy: "Pravidlá používania súborov cookie",
    header: "Používanie cookie na stránke!",
    allow: "Povoliť cookies"
  }
};
@NgModule({
  declarations: [
    AppComponent,
    ContactComponent,
    HowOnItComponent,
    CoverComponent,
    NavigationComponent,
    TechnologyRealizationComponent
  ],
  imports: [
    BrowserModule,
    NgcCookieConsentModule.forRoot(cookieConfig),
    RecaptchaModule,
    RecaptchaFormsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule
    
  ],
  providers: [
    SendMailService,
    SettingsService
  ],
  bootstrap: [
    AppComponent
  ]
})
export class AppModule { }
