<div class="pageWrap">
  <!-- <div class="site-mobile-menu site-navbar-target">
      <div class="site-mobile-menu-header">
          <div class="site-mobile-menu-close mt-3">
              <span class="icon-close2 js-menu-toggle"></span>
          </div>
      </div>
      <div class="site-mobile-menu-body"></div>
  </div> -->

  <navigation-component></navigation-component>
  <header class="myNavbar py-4 bg-white stickyTopSpace0">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-11 col-xl-2">
                <div class="mb-0">
                    <a href="https://www.sajasoft.sk" class="text-black h2 mb-0"><img class="p-0" src="./assets/img/SAJALogo.png" style="width: 240px;" /></a>
                </div>
            </div>
            <div class="col-12 col-md-10 d-none d-xl-block">
                <nav class="navNavigation position-relative text-right mt-2">
                    <ul class="navMenu hlMenu cloneNav mr-auto d-none d-lg-block">
                        <li><a href="#naviDomov" class="nav-link">Domov</a></li>
                        <li><a href="#naviTechnologia" class="nav-link">Technológia a realizácie</a></li>
                        <!-- <li><a href="#naviRealizacie" class="nav-link">Realizácie</a></li> -->
                        <li><a href="#naviAkoNaTo" class="nav-link">Ako na to</a></li>
                        <li><a href="#naviKontakt" class="nav-link">Kontakt</a></li>
                    </ul>
                </nav>
            </div>
            <!-- <div class="d-inline-block d-xl-none ml-md-0 mr-auto py-3" style="position: relative; top: 3px;"><a href="#" class="pageMenuToggle js-menu-toggle text-black"><span class="icon-menu h3"></span></a></div> -->
        </div>
    </div>
</header>
  <cover-component></cover-component>
  <a id="back2Top" href="#"><div class="unitIconWhite"><span class="text-center icon-arrow-right"></span></div></a>
  <technology-realization-component></technology-realization-component>
  <how-on-it-component></how-on-it-component>
  <contact-component></contact-component>
  <footer class="pageFooter">
      <div class="container">
          <div class="col-md-12 text-center">
              <a><img src="./assets/img/SAJALogoCenterBgBlack.png" style="width: 300px;" /></a>
          </div>
          <div class="row pt-4 mt-4 text-center">
              <div class="col-md-12">
                  <div class="border-top pt-5">
                      <p>Copyright &copy; 2021 Miroslav Šoltes - SAJA Soft, s.r.o.</p>
                  </div>
              </div>

          </div>
      </div>
  </footer>
</div>

