<div class="container">
    <main role="main" class="pb-3"> 
      <section class="pageSection pb-1" id="naviTechnologia">
        <div class="container">
            <div class="row justify-content-center pt-5">
                <div class="col-md-8 text-center">
                    <h2 class="text-black h2 pageSectionHead text-center">Technológia</h2>
                    <p class="lead">Používame prevažne technológie od spoločnosti Microsoft. </p>
                </div>
            </div>
            <div class="row align-items-stretch">
                <div class="col-md-6 col-lg-4 mb-4 mb-lg-4" data-aos="fade-up">
                    <div class="unit d-flex">
                        <div class="unitIcon mr-4"><span class="text-primary icon-database"></span></div>
                        <div>
                            <h3>Databáza</h3>
                            <p>Microsoft SQL Server<br />Postgre SQL Server<br />Oracle SQL Server<br />MySQL SQL Server</p>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 col-lg-4 mb-4 mb-lg-4" data-aos="fade-up" data-aos-delay="200">
                    <div class="unit d-flex">
                        <div class="unitIcon mr-4"><span class="text-primary icon-developer_mode"></span></div>
                        <div>
                            <h3>Programovanie</h3>
                            <p>Microsoft C#<br />Microsoft .Net framework<br />Microsoft .Net Core framework<br />Microsoft Entity framework<br />Microsoft Entity Core framework<br />Microsoft Xamarin framework<br />Microsoft .Net MVC<br />Microsoft .Net Core MVC<br />Microsoft TypeScript<br />JavaScript<br />Google Angular</p>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 col-lg-4 mb-4 mb-lg-4" data-aos="fade-up" data-aos-delay="400">
                    <div class="unit d-flex">
                        <div class="unitIcon mr-4"><span class="text-primary icon-settings_input_composite"></span></div>
                        <div>
                            <h3>Komponenty</h3>
                            <p>Microsoft ASP.Net<br />DevExpress<br />Telerik</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    
    <section class="pageSection pt-1" id="naviRealizacie">
        <div class="container">
            <div class="row justify-content-center mb-5">
                <div class="col-md-8 text-center" data-aos="fade-up">
                    <h2 class="text-black h2 pageSectionHead text-center">Realizácie</h2>
                </div>
            </div>
    
            <div class="row justify-content-center">
                <div class="col-md-6 col-lg-4 mb-4 mb-lg-4" data-aos="fade-up">
                    <div class="unit d-flex">
                        <div class="unitIcon2 mr-3"><span class="icon-personal_video"></span></div>
                        <div>
                            <h3 class="h3blue">NPK SK</h3>
                            <p>Web aplikácia Národný potravinový katalóg</p>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 col-lg-4 mb-4 mb-lg-4" data-aos="fade-up" data-aos-delay="100">
                    <div class="unit d-flex">
                        <div class="unitIcon2 mr-3"><span class="icon-settings"></span></div>
                        <div>
                            <h3 class="h3blue">NPK SK Performance</h3>
                            <p>Záťažové testy NPK SK</p>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 col-lg-4 mb-4 mb-lg-4" data-aos="fade-up" data-aos-delay="200">
                    <div class="unit d-flex">
                        <div class="unitIcon2 mr-3"><span class="icon-personal_video"></span></div>
                        <div>
                            <h3 class="h3blue">eSAM</h3>
                            <p>Web aplikácia digitálne mesto</p>
                        </div>
                    </div>
                </div>
    
            </div>
    
            <div class="row justify-content-center">
                <div class="col-md-6 col-lg-4 mb-4 mb-lg-4" data-aos="fade-up" data-aos-delay="300">
                    <div class="unit d-flex">
                        <div class="unitIcon2 mr-3"><span class="icon-personal_video"></span></div>
                        <div>
                            <h3 class="h3blue">Arch NBS</h3>
                            <p>Web aplikácia archívu</p>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 col-lg-4 mb-4 mb-lg-4" data-aos="fade-up" data-aos-delay="400">
                    <div class="unit d-flex">
                        <div class="unitIcon2 mr-3"><span class="icon-phone_iphone"></span></div>
                        <div>
                            <h3 class="h3blue">eSanitka</h3>
                            <p>Mobilná aplikácia digitalizácia sanitiek</p>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 col-lg-4 mb-4 mb-lg-4" data-aos="fade-up" data-aos-delay="500">
                    <div class="unit d-flex">
                        <div class="unitIcon2 mr-3"><span class="icon-personal_video"></span></div>
                        <div>
                            <h3 class="h3blue">Subst server</h3>
                            <p>Web aplikácia pripojenia</p>
                        </div>
                    </div>
                </div>
            </div>
    
            <div class="row justify-content-center">
                <div class="col-md-6 col-lg-4 mb-4 mb-lg-4" data-aos="fade-up" data-aos-delay="600">
                    <div class="unit d-flex">
                        <div class="unitIcon2 mr-3"><span class="icon-personal_video"></span></div>
                        <div>
                            <h3 class="h3blue">HARA SK</h3>
                            <p>Windows aplikácia objednávok</p>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 col-lg-4 mb-4 mb-lg-4" data-aos="fade-up" data-aos-delay="700">
                    <div class="unit d-flex">
                        <div class="unitIcon2 mr-3"><span class="icon-settings"></span></div>
                        <div>
                            <h3 class="h3blue">SmartCity</h3>
                            <p>Web servisy mobilnej aplikácie SmartCity</p>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 col-lg-4 mb-4 mb-lg-4" data-aos="fade-up" data-aos-delay="800">
                    <div class="unit d-flex">
                        <div class="unitIcon2 mr-3"><span class="icon-personal_video"></span></div>
                        <div>
                            <h3 class="h3blue">Dsystem</h3>
                            <p>Web aplikácia dochádzkového systému</p>
                        </div>
                    </div>
                </div>
            </div>
    
            <div class="row justify-content-center">
                <div class="col-md-6 col-lg-4 mb-4 mb-lg-4" data-aos="fade-up" data-aos-delay="900">
                    <div class="unit d-flex">
                        <div class="unitIcon2 mr-3"><span class="icon-phone_iphone"></span></div>
                        <div>
                            <h3 class="h3blue">Archiles</h3>
                            <p>Mobilná aplikácia automatizované účtovanie</p>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 col-lg-4 mb-4 mb-lg-4" data-aos="fade-up" data-aos-delay="1000">
                    <div class="unit d-flex">
                        <div class="unitIcon2 mr-3"><span class="icon-phone_iphone"></span></div>
                        <div>
                            <h3 class="h3blue">365 banka</h3>
                            <p>Mobilná aplikácia Poštovej banky</p>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 col-lg-4 mb-4 mb-lg-4" data-aos="fade-up" data-aos-delay="1100">
                    <div class="unit d-flex">
                        <div class="unitIcon2 mr-3"><span class="icon-phone_iphone"></span></div>
                        <div>
                            <h3 class="h3blue">Obhliadky</h3>
                            <p>Mobilná aplikácia obhliadok vozidiel</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    </main>
</div>